<template>
    <div class="page-container">
        <div class="header">
            <img src="@/assets/images/public/head-back-black-icon.png" alt="" @click="back">
        </div>
        <div class="iframe-container">
            <iframe id="iframe" width="100%" height="100%" :src="url" frameborder="0"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            url: '',
        };
    },
    created() {
        this.url = this.$route.query.url
    },
    methods: {
        back() {
            this.$router.replace({
                name: "StayPay"
            })
        }
    }
}
</script>

<style scoped lang="scss">
.page-container {
    display: flex;
    flex-direction: column;
    color: #F4F5FC;
    min-height: 100vh;

    .header {
        padding: 22px 40px;

        img {
            width: 44px;
            height: 44px;
        }
    }

    .iframe-container {
        height: 90vh;

        iframe {
            height: 100%;
        }
    }
}
</style>